import axios from 'axios';
import { colors } from '../../assets/colors';
import invoierLogo from '../../assets/images/invoier.png';
import { RowView, Text } from '../../helpers';
import { IInvoice } from '../../interfaces';
import UserInfoSchema from '../../schemas/UserInfoSchema';
import { setGlobalState } from '../../state';
import AnimatedLoader from '../AnimatedLoader/AnimatedLoader';
import CustomForm from '../CustomForm/CustomForm';
import RoundBox from '../RoundBox/RoundBox';
import PaymentProvider from './PaymentProvider';
import {formFieldsConverter} from '../../functions';

type EditUserInfoProps = {
    editting?: boolean;
    invoice: IInvoice;
    loading: boolean
};

const EditUserInfo = ({ invoice, loading }: EditUserInfoProps) => {
    const initialValues: Partial<IInvoice['customer']> = {
        email: invoice.customer.email,
        name: invoice.customer.name,
        street_address: invoice.customer.street_address,
        care_of: invoice.customer.care_of,
        postal_code: invoice.customer.postal_code,
        city: invoice.customer.city,
        phone_number: invoice.customer.phone_number
    };


    const submitUserInfoForm = async (values: Partial<IInvoice['customer']>) => {
        setGlobalState('loading', true);
        await axios
            .post(
                process.env.REACT_APP_API_BASE_URL +
                    'web/invoice/' +
                    invoice.uuid +
                    '/sign_invoice/',
                {
                    payment_method: invoice.payment_method,
                    id_number: invoice.customer.id_number,
                    customer_email: values.email,
                    phone_number: values.phone_number,
                    name: values.name,
                    street_address: values.street_address,
                    care_of: values.care_of,
                    postal_code: values.postal_code,
                    city: values.city,
                    intent_uuid: invoice.intent_uuid,
                    attachments: invoice.attachments
                }
            )
            .then((res) => (window.location.href = res.data.url))
            .catch((err) => {
                console.log(err);
            });
        setGlobalState('loading', false);
    };

    const EditUserInfoFields = formFieldsConverter(Object.keys(invoice.customer));

    return (
        <RoundBox>
            {loading && <AnimatedLoader />}
            <Text
                i="Fakturauppgifter"
                fontWeight="normal"
                style={{ fontSize: '18px', marginBottom: '13px' }}
            />
            {invoice.customer.type !== 'B2C' && (
                <>
                    <Text
                        i="Organisationsnummer"
                        color={colors.lightGrey}
                        style={{ marginTop: '13px', marginBottom: '13px' }}
                    />
                    <Text
                        i={invoice.organization.id_number}
                        color="#2A2E43"
                        style={{ marginBottom: '29px' }}
                    />
                </>
            )}

            <CustomForm
                initialValues={initialValues}
                validationSchema={UserInfoSchema}
                onFormSubmit={submitUserInfoForm}
                fields={EditUserInfoFields}
                buttonText="Slutför"
            />

            {invoice.invoice_type === 'fast' && (
                <RowView style={{ justifyContent: 'center', marginTop: '27px' }}>
                    <PaymentProvider
                        logoSrc={invoierLogo}
                        style={{ width: '100px', marginLeft: '5px' }}
                    />
                </RowView>
            )}
        </RoundBox>
    );
};

export default EditUserInfo