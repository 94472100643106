import { ReactElement, useState } from 'react';
import { useGlobalState } from '../../state';
import ConfirmedUserInfo from './ConfirmedUserInfo';
import EditUserInfo from './EditUserUinfo';
import InvoiceUserInfo from './InvoiceUserInfo';

const InvoiceDetails = () => {
    const [invoice] = useGlobalState('invoice');
    const [loading] = useGlobalState('loading');
    const [showInfo, setShowInfo] = useState<'manual' | 'confirmed' | 'start'>('start');


    const invoiceRenderOption = (showInfo: 'manual' | 'confirmed' | 'start'): ReactElement => {
        switch (true) {
            case showInfo === 'start':
                return (
                    <InvoiceUserInfo
                        invoice={invoice}
                        loading={loading}
                        setShowInfo={setShowInfo}
                    />
                );
            case showInfo === 'confirmed':
                return (
                    <ConfirmedUserInfo
                        invoice={invoice}
                        loading={loading}
                        setShowInfo={setShowInfo}
                    />
                );
            case showInfo === 'manual':
                return <EditUserInfo invoice={invoice} loading={loading} />;
            default:
                return <div>Something went wrong</div>;
        }
    };

    return <>{invoiceRenderOption(showInfo)}</>;
};

export default InvoiceDetails;
