import customerKeyToDisplayName from './customerKeyToDisplayName';

const formFieldsConverter = (arr: string[], skipField?: string): { name: string }[] => {
    const result: { name: string }[] = [];
    // if (arr.indexOf('email') !== -1) {
    //     arr[arr.indexOf('email')] = 'customer_email';
    // }
    arr.forEach((item) => {
        if (customerKeyToDisplayName(item) === '' || item === skipField) {
            return;
        }
        const obj: { name: string } = {
            name: ''
        };
        obj.name = item;
        result.push(obj);
    });
    return result;
};

export default formFieldsConverter;
