import styled, { CSSProperties } from 'styled-components';
import { Text } from '../../helpers';
import RoundBox from '../RoundBox/RoundBox';
import { IInvoice } from '../../interfaces';

type ErrorProps = {
    invoice?: IInvoice;
    message: string;
    reason?: string;
    nf?: boolean
    headerStyle?: CSSProperties
    textStyle?: CSSProperties
    containerStyle?: CSSProperties
};
const InfoList = styled.ul``;
const InfoRow = styled.li`
    display: flex;
    gap: 5px;
`;

const Error = ({ message, reason, invoice, nf = true, headerStyle, textStyle, containerStyle }: ErrorProps) => {
    switch (reason) {
        case 'cancelled':
            return (
                <RoundBox
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '60px',
                        marginBottom: '20%',
                        height: '10vh'
                    }}>
                    <Text i={message} header fontWeight="bold" />
                    {invoice && (
                        <>
                            <Text i="Invoice info:" />
                            <InfoList>
                                <InfoRow>
                                    <Text i="Organization: " />
                                    <Text i={invoice.organization.name} />
                                </InfoRow>
                                <InfoRow>
                                    <Text i="Customer: " />
                                    <Text i={invoice.customer.name} />
                                </InfoRow>
                            </InfoList>
                        </>
                    )}
                </RoundBox>
            );
        default:
            return (
                <RoundBox style={{ marginTop: '60px', marginBottom: '20%', ...containerStyle }}>
                    {nf && <Text i="404" header fontWeight="bold" style={headerStyle}/>}
                    {message && <Text i={message} style={{ marginTop: '5%', ...textStyle }} />}
                </RoundBox>
            );
    }
};

export default Error;
