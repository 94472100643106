import styled from 'styled-components';
import { decimalToKr } from '../../functions';
import { Line, RowView, Text } from '../../helpers';
import { useGlobalState } from '../../state';
import RoundBox from '../RoundBox/RoundBox';

type CustomerDetailsProps = {
    status?: string;
};

type WrapperProps = {
    status?: string;
};

const Wrapper = styled.div<WrapperProps>`
    margin-top: ${(props) => (props.status === 'failed' ? '3vh' : '8vh')};
`;

const EndCustomer = styled(Text)`
    /* margin-bottom: 1vh; */
`;

const LineList = styled.ul`
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 10px;
`;

const CustomerDetails = ({ status }: CustomerDetailsProps) => {
    const [invoice] = useGlobalState('invoice');

    const organization = invoice.organization;
    const lines = invoice.lines;
    return (
        <Wrapper status={status}>
            <RoundBox>
                <EndCustomer i={organization.name} fontWeight="bold" header />
                <RowView
                    style={{
                        flexDirection: 'column',
                        alignItems: 'flex-start'
                    }}>
                    <Text i={`Fakturaunderlag #${invoice.invoice_number}`} />
                    <Line />
                </RowView>
                <LineList>
                    {lines.map((item, index) => {
                        return (
                            <li key={index}>
                                {item.description.includes('Checkout') && <Line margin="10px 0" />}
                                <RowView style={{ gap: '10px' }}>
                                    <Text i={item.description} />
                                    <Text
                                        i={`${Number(item.amount_excl_vat).toLocaleString('sv-SE', {
                                            maximumFractionDigits: 2
                                        })} kr`}
                                        style={{ textWrap: 'nowrap' }}
                                    />
                                </RowView>
                                <RowView style={{ gap: '5px' }}>
                                    <Text i={`Moms ${item.vat_rate}%`} />
                                    <Text
                                        i={`${Number(item.vat_amount).toLocaleString('sv-SE', {
                                            maximumFractionDigits: 2
                                        })} kr`}
                                        style={{ textWrap: 'nowrap' }}
                                    />
                                </RowView>
                            </li>
                        );
                    })}
                </LineList>
                <Line margin="10px 0 20px 0" />
                {invoice.lines.length > 1 && (
                    <RowView style={{ marginTop: 20 }}>
                        <Text i="Varav moms" />
                        <Text
                            i={`${Number(invoice.vat).toLocaleString('sv-SE', {
                            maximumFractionDigits: 2
                        })} kr`}
                            style={{ textWrap: 'nowrap' }}
                        />
                    </RowView>
                )}
                <RowView style={{ marginTop: '2vh' }}>
                    <Text i="Att betala" fontWeight="bold" />
                    <Text
                        i={`${Number(invoice.total_amount).toLocaleString('sv-SE', {
                            maximumFractionDigits: 2
                        })} kr`}
                        fontWeight="bold"
                        style={{ textWrap: 'nowrap' }}
                    />
                </RowView>
            </RoundBox>
        </Wrapper>
    );
};

export default CustomerDetails;
