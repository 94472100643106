import { Dispatch, ReactNode, SetStateAction } from 'react';
import styled, { CSSProperties } from 'styled-components';
import { Text } from '../../helpers/index';

type Props = {
    children?: ReactNode;
    className?: string;
    color?: string;
    i: string;
    fontWeight?: string;
    style?: CSSProperties;
    onClick?: Dispatch<SetStateAction<unknown>>;
    type?: 'button' | 'submit';
    disabled?: boolean
};

const Wrapper = styled.button`
    background: linear-gradient(116deg, #ed1e79, #9e005d);
    border-radius: 26px;
    outline: none;
    border: none;
    width: 100%;
    min-height: 52px;
    height: 52px;
    opacity: 1;
    display: grid;
    place-content: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    user-select:none;
    &:hover, &:focus {
        filter: brightness(0.85);
        transform: scale(1.03)
        }
`;

const Button = ({ children, className, color, i, fontWeight, style, onClick, type, disabled}: Props) => {
    return (
        <Wrapper className={className} style={style} onClick={onClick} type={type} disabled={disabled}>
            <Text color={color} i={i} fontWeight={fontWeight} />
            {children}
        </Wrapper>
    );
};

export default Button;
