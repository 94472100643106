import { ReactNode } from 'react';
import { CSSProperties } from 'styled-components';

type Props = {
    amount?: number;
    children: ReactNode;
    style?: CSSProperties;
};

const Clamp = ({ amount, children, style }: Props) => (
    <div style={{ paddingInline: `${amount || 5}%`, ...style }}>{children}</div>
);

export default Clamp;
