import { ReactElement } from 'react';
import InvoiceFailed from './InvoiceFailed';
import InvoiceSuccess from './InvoiceSuccess';

type InvoiceStatusProps = {
    status?: string;
    withAttachments: boolean
};

const InvoiceStatus = ({ status, withAttachments }: InvoiceStatusProps): ReactElement => {
    return <>{status === 'success' ? <InvoiceSuccess withAttachments={withAttachments} /> : <InvoiceFailed />}</>;
};

export default InvoiceStatus;
