import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import styled from 'styled-components';
import { colors } from '../../assets/colors';
import { Text } from '../../helpers';
import RoundBox from '../RoundBox/RoundBox';

const IconTextWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    height: 35px;
`;

const InvoiceSuccess = ({withAttachments}: {withAttachments: boolean}) => {
    return (
        <RoundBox
            style={{
                display: 'flex',
                justifyContent: 'center',
                gap: '10px',
                alignItems: 'flex-start',
                flexDirection: 'column',
                padding: '24px',
                marginTop: '5vh',
                marginBottom: '-4.5vh'
            }}>
            <IconTextWrapper>
                <span style={{ display: 'flex', alignItems: 'center' }}>
                    <CheckCircleIcon fontSize="large" style={{ fill: 'green' }} />
                </span>
                <Text
                    i="Klart"
                    fontWeight="600"
                    color={colors.dark}
                    style={{
                        textAlign: 'center',
                        fontSize: '24px',
                        lineHeight: '35px'
                    }}
                />
            </IconTextWrapper>
            <Text
                i={`${
                    withAttachments
                        ? 'Fakturan (och bilagan) har undertecknades av dig. Fakturan skickas nu till din e-post, samtidigt som den andra parterna blivit notifierad om att signera bilagan.'
                        : 'Fakturan har nu undertecknats och skickats ut till dig via e-post.'
                }`}
                color={colors.lightGrey}
            />
        </RoundBox>
    );
};

export default InvoiceSuccess;
