import axios from 'axios';
import { colors } from '../../assets/colors';
import { Text } from '../../helpers';
import { IInvoice } from '../../interfaces';
import { setGlobalState } from '../../state';
import AnimatedLoader from '../AnimatedLoader/AnimatedLoader';
import Button from '../Button/Button';
import { BankIDLogo } from '../Logos';
import Address from './Address';

type ConfirmedUserInfoPropsType = {
    invoice: IInvoice;
    setShowInfo: React.Dispatch<React.SetStateAction<'manual' | 'confirmed' | 'start'>>;
    loading: boolean;
};


const ConfirmedUserInfo = ({ invoice, loading, setShowInfo }: ConfirmedUserInfoPropsType) => {
    return (
        <>
            {loading && <AnimatedLoader />}
            <Text
                i="Fakturauppgifter"
                fontWeight="normal"
                style={{ fontSize: '18px', marginBottom: '13px' }}
            />
            <Address setShowInfo={setShowInfo} />
            <Button
                i="Godkänn med BankID "
                color={colors.white}
                fontWeight="bold"
                style={{
                    marginTop: '8%',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '15px'
                }}
                onClick={async () => {
                    setGlobalState('loading', true);
                    await axios
                        .post(
                            process.env.REACT_APP_API_BASE_URL +
                                'web/invoice/' +
                                invoice.uuid +
                                '/sign_invoice/',
                            {
                                payment_method: invoice.payment_method,
                                id_number: invoice.customer.id_number,
                                customer_email: invoice.customer.email,
                                phone_number: invoice.customer.phone_number,
                                name: invoice.customer.name,
                                street_address: invoice.customer.street_address,
                                care_of: invoice.customer.care_of,
                                postal_code: invoice.customer.postal_code,
                                city: invoice.customer.city,
                                intent_uuid: invoice.intent_uuid,
                                attachments: invoice.attachments
                            }
                        )
                        .then((res) => (window.location.href = res.data.url))
                        .catch((err) => {
                            console.log(err);
                        });
                    setGlobalState('loading', false);
                }}>
                <BankIDLogo />
            </Button>
            <Button
                i="Godkänn utan BankID"
                color={colors.white}
                fontWeight="bold"
                style={{
                    marginTop: '8%',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '15px'
                }}
                onClick={async () => {
                    setGlobalState('loading', true);
                    await axios
                        .post(
                            process.env.REACT_APP_API_BASE_URL +
                                'web/invoice/' +
                                invoice.uuid +
                                '/sign_invoice/',
                            {
                                payment_method: invoice.payment_method,
                                id_number: invoice.customer.id_number,
                                customer_email: invoice.customer.email,
                                name: invoice.customer.name,
                                street_address: invoice.customer.street_address,
                                care_of: invoice.customer.care_of,
                                postal_code: invoice.customer.postal_code,
                                phone_number: invoice.customer.phone_number,
                                city: invoice.customer.city,
                                intent_uuid: invoice.intent_uuid,
                                attachments: invoice.attachments
                                
                            }
                        )
                        .then((res) => {
                            console.log(res);
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                    setGlobalState('loading', false);
                }}
            />
        </>
    );
};

export default ConfirmedUserInfo
