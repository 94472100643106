import { IInvoice } from "../interfaces";

type DateTraformType = Date | string | null;


    const dateTransform = (date: DateTraformType, invoice: IInvoice) => {
        let dueDate;
        if (date === null) {
            dueDate = new Date(invoice.created ?? '');
            dueDate.setDate(dueDate.getDate() + invoice.due_days);
        } else {
            dueDate = new Date(date);
        }
        const formatedDueDate = dueDate.toUTCString();
        const i = formatedDueDate.indexOf(' ');
        return formatedDueDate.slice(i, formatedDueDate.length - 12);
};
    
export default dateTransform;