import CancelIcon from '@mui/icons-material/Cancel';
import { ReactElement, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { RoundBox } from '../../components';

export const AttachmentsSigningFailed = () => {
    const { invoice_id } = useParams();

    const navigate = useNavigate();

    useEffect(() => {
        navigate(`/invoice/attachments/${invoice_id}/`, {
            replace: true,
            state: {
                failed: 'redirected'
            }
        });
    });

    return <></>;
};


const FailedHeader = styled.p`
    font-weight: 600;
    text-align: center;
    color: red;
`
 


export const AttachmentFailedComponent = (): ReactElement => {
    return (
            <RoundBox
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '10px',
                    alignItems: 'center',
                    marginTop: '4vh',
                    padding: '12px'
                }}>
                <>
                    <CancelIcon  style={{ fill: 'red', fontSize: 35, float: "right"}} />
                    <FailedHeader>
                    Undertecknandet av dokumentet misslyckades. Försök igen.
                    </FailedHeader>
                </>
            </RoundBox>
    );
};