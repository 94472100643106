import * as React from 'react';
import styled, { CSSProperties } from 'styled-components';

type Props = {
    children?: React.ReactNode;
    style?: CSSProperties;
};

const Wrapper = styled.div`
    background: white;
    border-radius: 12px;
    padding: 24px;
    margin-bottom: 40px;
    /* @media screen and (min-width: 960px) {
        padding: 50px 100px;
    } */
     @media screen and (max-width: 400px) {
        padding: 12px
     }
`;

const RoundBox = ({ children, style }: Props) => <Wrapper style={style}>{children}</Wrapper>;

export default RoundBox;
