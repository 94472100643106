import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { IInvoice } from '../../interfaces';
import { setGlobalState } from '../../state';

const useGetInvoice = (invoice_id: string | undefined) => {
    const [fetchedInvoice, setFetchedInvoice] = useState(false);
    const [invoice, setInvoice] = useState<IInvoice>();

    const fetchInvoice = useCallback(async () => {
        axios
            .get(process.env.REACT_APP_API_BASE_URL + 'web/invoice/' + invoice_id + '/')
            .then((res) => {
                setFetchedInvoice(true);
                setInvoice(invoice);
                setGlobalState('invoice', res.data);
            })
            .catch((err) => {
                console.log(err);
                setFetchedInvoice(true);
            });
    }, []);

    useEffect(() => {
        fetchInvoice();
    }, [fetchInvoice]);

    return { invoice, fetchedInvoice };
};

export default useGetInvoice;
