import { Form, Formik } from 'formik';
import { colors } from '../../assets/colors';
import { Text } from '../../helpers';
import Button from '../Button/Button';
import FormField from '../FormField/FormField';
import { BankIDLogo } from '../Logos';

type FormFieldsType = {
    name: string;
    placeholder?: string;
};

type FormPropsType = {
    formTitle?: string;
    initialValues: { [key: string]: string };
    validationSchema: unknown;
    // eslint-disable-next-line
    onFormSubmit: (args: any) => void;
    fields: FormFieldsType[];
    buttonText?: string;
};

const CustomForm = ({
    formTitle,
    initialValues,
    validationSchema,
    onFormSubmit,
    fields,
    buttonText
}: FormPropsType) => {
    return (
        <>
            {formTitle && <Text i={formTitle} fontWeight="bold" style={{ marginBottom: '6%' }} />}
            <Formik
                initialValues={initialValues}
                onSubmit={onFormSubmit}
                validationSchema={validationSchema}>
                <Form>
                    {fields.map((item) => {
                        return (
                            <FormField
                                key={item.name}
                                name={item.name}
                                placeholder={item.placeholder}
                            />
                        );
                    })}
                    <Button
                        i={buttonText ? buttonText : 'Submit'}
                        color={colors.white}
                        fontWeight="bold"
                        style={{
                            marginTop: '8%',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '15px'
                        }}
                        type="submit">
                        <BankIDLogo />
                    </Button>
                </Form>
            </Formik>
        </>
    );
};

export default CustomForm;
