import * as React from 'react';
import { colors } from '../../assets/colors';

type Props = {
    color?: string;
    margin?: string;
};

const Line: React.FC<Props> = ({ color, margin = '20px 0' }) => (
    <div
        style={{
            width: '100%',
            height: '1px',
            backgroundColor: color || colors.grey,
            margin: margin
        }}
    />
);

export default Line;
