import axios from 'axios';
import { useEffect } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import invoierLogo from '../../assets/images/invoier.png';
import { getFormFields, getInitialValues } from '../../functions';
import { Line, RowView, Text } from '../../helpers';
import { IInvoice } from '../../interfaces';
import { setGlobalState } from '../../state';
import AnimatedLoader from '../AnimatedLoader/AnimatedLoader';
import CustomForm from '../CustomForm/CustomForm';
import RoundBox from '../RoundBox/RoundBox';
import Address from './Address';
import PaymentProvider from './PaymentProvider';


type InvoiceUserInfoPropType = {
    invoice: IInvoice;
    setShowInfo: React.Dispatch<React.SetStateAction<"manual" | "confirmed" | "start">>;
    loading: boolean
};

const Wrapper = styled.div``;
const InvoiceUserInfo = ({ invoice, setShowInfo, loading }: InvoiceUserInfoPropType) => {
    useEffect(() => {
        for (const key in invoice.customer) {
            if (
                key === 'phone_number' ||
                key === 'care_of' ||
                key === 'id_number' ||
                key === 'country'
            ) {
                continue;
            } else if (invoice.customer[key as keyof IInvoice['customer']] === '') {
                setShowInfo('manual');
                break;
            }
        }
    }, []);

    const InvoiceInvoiceUserInfoSchema = Yup.object({
        email: Yup.string().email('Invalid email').required('Required'),
        phone_number: Yup.number()
    });

    const initialValues = getInitialValues(invoice.customer);

    const onSubmit = async (values: Partial<IInvoice['customer']>) => {
        setGlobalState('loading', true);
        await axios
            .post(
                process.env.REACT_APP_API_BASE_URL +
                    'web/invoice/' +
                    invoice.uuid +
                    '/sign_invoice/',
                {
                    payment_method: invoice.payment_method,
                    id_number: invoice.customer.id_number,
                    customer_email: values.email,
                    phone_number: values.phone_number,
                    name: invoice.customer.name,
                    street_address: invoice.customer.street_address,
                    care_of: invoice.customer.care_of,
                    postal_code: invoice.customer.postal_code,
                    city: invoice.customer.city,
                    intent_uuid: invoice.intent_uuid,
                    attachments: invoice.attachments
                }
            )
            .then((res) => (window.location.href = res.data.url))
            .catch((err) => {
                console.log(err);
            });
        setGlobalState('loading', false);
    };
    return (
        <Wrapper>
            {loading && <AnimatedLoader />}
            <RoundBox style={{ padding: '16px' }}>
                <Text
                    i="Fakturauppgifter"
                    fontWeight="normal"
                    style={{ fontSize: '18px', marginBottom: '13px' }}
                />
                <Address setShowInfo={setShowInfo} />
                <CustomForm
                    initialValues={initialValues}
                    validationSchema={InvoiceInvoiceUserInfoSchema}
                    onFormSubmit={onSubmit}
                    fields={getFormFields(invoice.customer)}
                    buttonText="Slutför"
                />

                {invoice.invoice_type === 'fast' && (
                    <>
                        <Line />
                        <RowView style={{ justifyContent: 'center' }}>
                            <PaymentProvider
                                logoSrc={invoierLogo}
                                style={{ width: '100px', marginLeft: '5px' }}
                            />
                        </RowView>
                    </>
                )}
            </RoundBox>
        </Wrapper>
    );
};

export default InvoiceUserInfo
