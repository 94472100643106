

const Footer = () => {
    return (
        <div style={{ marginTop: '4%' }}>
            {/* <Text
                color={colors.lessdark}
                style={{ textAlign: 'center' }}
                i="Genom att slutföra godkänner du våra användarvillkor (privacy policy).Sedvanlig kreditkontroll görs för delbetalning och faktura. (påverkar  inte din kreditvärdighet)"
            /> */}
            {/* <FooterText>
                Genom att slutföra godkänner du våra användarvillkor{' '}
                <NavLink to="/privacy_policy">(privacy policy)</NavLink>.Sedvanlig kreditkontroll
                görs för delbetalning och faktura. (påverkar inte din kreditvärdighet)
            </FooterText> */}
        </div>
    );
};

export default Footer;
