import styled, { CSSProperties } from 'styled-components';

type LogoProps = {
    width?: string;
    height?: string;
    style?: CSSProperties
};

const Wrapper = styled.div<LogoProps>`
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    
`;
const DicoLogo = ({ width = "122px", height = 'auto', style }: LogoProps) => {
    return (
        <Wrapper width={width} height={height} style={style}>
            <svg
                width="100%" // Scale to 100% of the Wrapper's width
                height="100%" // Scale to 100% of the Wrapper's height
                viewBox="0 0 122 23" // Maintain aspect ratio
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M83.0317 7.2999C83.0499 7.63157 82.9953 7.9632 82.8718 8.27155C82.7483 8.5799 82.5588 8.8575 82.3167 9.0849C81.7467 9.56506 81.0122 9.80433 80.2688 9.75193H77.5547V4.7999H80.2688C81.0122 4.74792 81.7465 4.98714 82.3167 5.46692C82.5629 5.70136 82.7546 5.98707 82.8782 6.30384C83.0017 6.6206 83.0542 6.96064 83.0317 7.2999ZM87.5317 10.0379C87.8788 9.15009 88.0564 8.20516 88.0557 7.25193C88.0705 6.30522 87.8923 5.3654 87.5317 4.4899C87.1905 3.65569 86.6797 2.90144 86.0317 2.27491C85.3616 1.64779 84.5774 1.1549 83.7217 0.822911C82.777 0.471601 81.7767 0.29409 80.7688 0.298924H73.5068C73.2545 0.299714 73.0128 0.400222 72.8342 0.578557C72.6557 0.756892 72.5548 0.998586 72.5537 1.25092V20.9909C72.5545 21.2434 72.6552 21.4854 72.8337 21.6639C73.0123 21.8425 73.2543 21.9431 73.5068 21.9439H76.5789C76.8312 21.9429 77.0729 21.8421 77.2512 21.6635C77.4296 21.485 77.53 21.2432 77.5308 20.9909V14.2049H80.7458C81.7542 14.2193 82.756 14.0414 83.6978 13.6809C84.5524 13.3574 85.3368 12.8722 86.0078 12.2519C86.6753 11.639 87.1961 10.8836 87.5317 10.0419V10.0379Z"
                    fill="white"
                />
                <path
                    d="M97.8655 12.6372H93.5085L95.6755 6.68523L97.8655 12.6372ZM92.8655 0.946248L85.2934 20.6862C85.2378 20.8326 85.2186 20.9903 85.2375 21.1458C85.2564 21.3013 85.313 21.4498 85.4021 21.5786C85.4911 21.7074 85.61 21.8126 85.7488 21.8852C85.8875 21.9578 86.0419 21.9955 86.1985 21.9952H89.4365C89.6348 21.9931 89.8275 21.9304 89.9892 21.8157C90.1509 21.701 90.274 21.5396 90.3415 21.3532L91.8415 17.2532H99.5564L101.056 21.3532C101.124 21.5396 101.247 21.701 101.409 21.8157C101.57 21.9304 101.763 21.9931 101.961 21.9952H105.319C105.475 21.9948 105.629 21.9566 105.767 21.8839C105.905 21.8111 106.024 21.7059 106.113 21.5773C106.202 21.4487 106.259 21.3005 106.278 21.1453C106.297 20.9901 106.278 20.8326 106.224 20.6862L98.6755 0.946248C98.6068 0.762446 98.483 0.604339 98.321 0.49355C98.159 0.382761 97.9667 0.324673 97.7705 0.327229H93.7705C93.5754 0.329507 93.3854 0.389524 93.2243 0.499684C93.0633 0.609845 92.9383 0.765234 92.8655 0.946248Z"
                    fill="white"
                />
                <path
                    d="M116.155 0.565171L111.916 7.35118L107.678 0.565171C107.635 0.494755 107.576 0.436071 107.505 0.394486C107.435 0.352901 107.355 0.32974 107.273 0.327164H102.535C102.448 0.326459 102.363 0.349139 102.289 0.392808C102.214 0.436476 102.153 0.4995 102.112 0.575181C102.07 0.650862 102.05 0.736377 102.053 0.822648C102.056 0.908918 102.083 0.992732 102.129 1.06517L109.273 12.1852V15.1852C109.273 15.3113 109.323 15.4322 109.412 15.5215C109.502 15.6108 109.622 15.6614 109.749 15.6622H113.774C113.9 15.6616 114.021 15.6112 114.11 15.5218C114.199 15.4324 114.249 15.3114 114.25 15.1852V12.1852L121.465 1.06517C121.512 0.992732 121.538 0.908918 121.541 0.822648C121.544 0.736377 121.524 0.650862 121.482 0.575181C121.441 0.4995 121.38 0.436476 121.305 0.392808C121.231 0.349139 121.146 0.326459 121.06 0.327164H116.535C116.457 0.33317 116.382 0.357957 116.316 0.3994C116.249 0.440842 116.194 0.497716 116.155 0.565171Z"
                    fill="white"
                />
                <path
                    d="M113.797 17.186H109.772C109.509 17.186 109.296 17.3991 109.296 17.662V21.544C109.296 21.8069 109.509 22.02 109.772 22.02H113.797C114.06 22.02 114.273 21.8069 114.273 21.544V17.662C114.273 17.3991 114.06 17.186 113.797 17.186Z"
                    fill="white"
                />
                <path
                    d="M37.9558 5.04205C39.106 4.9421 40.261 5.16826 41.2886 5.69461C42.3162 6.22097 43.1749 7.02623 43.7659 8.01807C43.7976 8.07398 43.8402 8.12287 43.8914 8.16177C43.9425 8.20068 44.0011 8.22877 44.0635 8.24438C44.1258 8.26 44.1907 8.26279 44.2542 8.25259C44.3176 8.2424 44.3785 8.21942 44.4329 8.18506L47.8848 6.08505C47.9408 6.05343 47.9898 6.01073 48.0288 5.95956C48.0678 5.9084 48.0959 5.84985 48.1116 5.78745C48.1272 5.72504 48.1299 5.66009 48.1196 5.59659C48.1094 5.53309 48.0863 5.47235 48.0518 5.41806C47.0442 3.77463 45.6335 2.4159 43.9534 1.47083C42.2732 0.525752 40.3793 0.0256938 38.4517 0.0180664C35.4849 0.0228272 32.6412 1.20355 30.5435 3.30145C28.4457 5.39936 27.2652 8.2433 27.2607 11.2101C27.2655 14.1766 28.446 17.0204 30.5437 19.118C32.6414 21.2157 35.4851 22.3963 38.4517 22.4011C40.3833 22.3965 42.2812 21.8947 43.9626 20.9438C45.6441 19.9929 47.0524 18.6251 48.0518 16.972C48.0862 16.9178 48.1094 16.8572 48.1196 16.7938C48.1299 16.7303 48.127 16.6655 48.1113 16.6031C48.0957 16.5408 48.0678 16.4824 48.0288 16.4313C47.9898 16.3802 47.9407 16.3376 47.8848 16.3061L44.4329 14.2061C44.3785 14.1717 44.3176 14.1487 44.2542 14.1385C44.1907 14.1283 44.1258 14.1311 44.0635 14.1467C44.0011 14.1623 43.9425 14.1905 43.8914 14.2294C43.8402 14.2683 43.7976 14.3171 43.7659 14.373C43.2172 15.2908 42.4391 16.0501 41.5081 16.5761C40.5771 17.1021 39.5251 17.3767 38.4558 17.373C37.584 17.3706 36.7224 17.1844 35.9275 16.8264C35.1326 16.4685 34.422 15.9469 33.8423 15.2958C33.2626 14.6446 32.8268 13.8786 32.5632 13.0475C32.2997 12.2165 32.2143 11.3393 32.3127 10.4731C32.4868 9.05752 33.1428 7.745 34.1704 6.75601C35.198 5.76702 36.5346 5.16182 37.9558 5.04205Z"
                    fill="white"
                />
                <path
                    d="M59.8862 17.4001C58.2457 17.3953 56.6737 16.7416 55.5137 15.5815C54.3537 14.4215 53.7001 12.8496 53.6953 11.2091C53.7001 9.56861 54.3537 7.99665 55.5137 6.83664C56.6737 5.67663 58.2457 5.02285 59.8862 5.0181C61.5269 5.02258 63.0991 5.67623 64.2593 6.83627C65.4195 7.99631 66.0734 9.56844 66.0781 11.2091C66.0757 12.8505 65.4227 14.424 64.262 15.5845C63.1012 16.7451 61.5276 17.398 59.8862 17.4001ZM59.8862 0.0170898C56.9195 0.0218506 54.0757 1.20257 51.978 3.30048C49.8803 5.39838 48.6998 8.24236 48.6953 11.2091C48.6998 14.1758 49.8803 17.0196 51.978 19.1174C54.0758 21.2151 56.9196 22.3956 59.8862 22.4001C62.853 22.3956 65.697 21.2151 67.7949 19.1174C69.8928 17.0197 71.0734 14.1759 71.0781 11.2091C71.0776 8.24097 69.8984 5.39454 67.7996 3.29575C65.7008 1.19695 62.8544 0.0176201 59.8862 0.0170898Z"
                    fill="white"
                />
                <path
                    d="M83.0317 7.00107C83.0499 7.33274 82.9953 7.66438 82.8718 7.97272C82.7483 8.28107 82.5588 8.55867 82.3167 8.78608C81.7467 9.26623 81.0122 9.5055 80.2688 9.4531H77.5547V4.50107H80.2688C81.0122 4.4491 81.7465 4.68832 82.3167 5.1681C82.5629 5.40253 82.7546 5.68825 82.8782 6.00501C83.0017 6.32177 83.0542 6.66181 83.0317 7.00107ZM87.5317 9.73908C87.8788 8.85126 88.0564 7.90633 88.0557 6.9531C88.0705 6.00639 87.8923 5.06657 87.5317 4.19108C87.1905 3.35686 86.6797 2.60261 86.0317 1.97608C85.3616 1.34896 84.5774 0.856076 83.7217 0.524083C82.777 0.172773 81.7767 -0.00473842 80.7688 9.6155e-05H73.5068C73.2545 0.000885803 73.0128 0.101393 72.8342 0.279729C72.6557 0.458064 72.5548 0.699758 72.5537 0.952092V20.6921C72.5545 20.9446 72.6552 21.1865 72.8337 21.3651C73.0123 21.5436 73.2543 21.6443 73.5068 21.6451H76.5789C76.8312 21.644 77.0729 21.5432 77.2512 21.3647C77.4296 21.1862 77.53 20.9444 77.5308 20.6921V13.9061H80.7458C81.7542 13.9204 82.756 13.7426 83.6978 13.3821C84.5524 13.0586 85.3368 12.5734 86.0078 11.9531C86.6753 11.3402 87.1961 10.5848 87.5317 9.74308V9.73908Z"
                    fill="white"
                />
                <path
                    d="M97.8655 12.3101H93.5085L95.6755 6.35808L97.8655 12.3101ZM92.8655 0.619099L85.2934 20.3591C85.2378 20.5055 85.2186 20.6632 85.2375 20.8187C85.2564 20.9741 85.313 21.1226 85.4021 21.2514C85.4911 21.3802 85.61 21.4855 85.7488 21.5581C85.8875 21.6306 86.0419 21.6684 86.1985 21.6681H89.4365C89.6348 21.6659 89.8275 21.6033 89.9892 21.4885C90.1509 21.3738 90.274 21.2125 90.3415 21.0261L91.8415 16.9261H99.5564L101.056 21.0261C101.124 21.2125 101.247 21.3738 101.409 21.4885C101.57 21.6033 101.763 21.6659 101.961 21.6681H105.319C105.475 21.6677 105.629 21.6295 105.767 21.5567C105.905 21.4839 106.024 21.3787 106.113 21.2501C106.202 21.1215 106.259 20.9733 106.278 20.8182C106.297 20.663 106.278 20.5055 106.224 20.3591L98.6755 0.619099C98.6068 0.435298 98.483 0.277191 98.321 0.166402C98.159 0.0556124 97.9667 -0.00247571 97.7705 8.09097e-05H93.7705C93.5754 0.00235865 93.3854 0.0623753 93.2243 0.172536C93.0633 0.282696 92.9383 0.438086 92.8655 0.619099Z"
                    fill="white"
                />
                <path
                    d="M116.155 0.238023L111.916 7.02403L107.678 0.238023C107.635 0.167606 107.576 0.108923 107.505 0.0673378C107.435 0.0257527 107.355 0.00259189 107.273 1.59749e-05H102.535C102.448 -0.000689618 102.363 0.0219908 102.289 0.0656593C102.214 0.109328 102.153 0.172351 102.112 0.248032C102.07 0.323713 102.05 0.409229 102.053 0.495499C102.056 0.58177 102.083 0.665584 102.129 0.738023L109.273 11.858V14.858C109.273 14.9842 109.323 15.1051 109.412 15.1944C109.502 15.2837 109.622 15.3342 109.749 15.335H113.774C113.9 15.3345 114.021 15.284 114.11 15.1947C114.199 15.1053 114.249 14.9843 114.25 14.858V11.858L121.465 0.738023C121.512 0.665584 121.538 0.58177 121.541 0.495499C121.544 0.409229 121.524 0.323713 121.482 0.248032C121.441 0.172351 121.38 0.109328 121.305 0.0656593C121.231 0.0219908 121.146 -0.000689618 121.06 1.59749e-05H116.535C116.457 0.00602151 116.382 0.0308087 116.316 0.0722511C116.249 0.113693 116.194 0.170568 116.155 0.238023Z"
                    fill="white"
                />
                <path
                    d="M113.797 17H109.772C109.509 17 109.296 17.2131 109.296 17.476V21.358C109.296 21.6209 109.509 21.834 109.772 21.834H113.797C114.06 21.834 114.273 21.6209 114.273 21.358V17.476C114.273 17.2131 114.06 17 113.797 17Z"
                    fill="white"
                />
                <path
                    d="M37.9558 5.02399C39.106 4.92403 40.261 5.15019 41.2886 5.67654C42.3162 6.2029 43.1749 7.00816 43.7659 8C43.7976 8.05591 43.8402 8.1048 43.8914 8.14371C43.9425 8.18261 44.0011 8.2107 44.0635 8.22632C44.1258 8.24193 44.1907 8.24473 44.2542 8.23453C44.3176 8.22433 44.3785 8.20135 44.4329 8.16699L47.8848 6.06699C47.9408 6.03536 47.9898 5.99267 48.0288 5.9415C48.0678 5.89033 48.0959 5.83178 48.1116 5.76938C48.1272 5.70698 48.1299 5.64202 48.1196 5.57852C48.1094 5.51502 48.0863 5.45428 48.0518 5.39999C47.0442 3.75656 45.6335 2.39783 43.9534 1.45276C42.2732 0.507686 40.3793 0.00762739 38.4517 0C35.4849 0.00476076 32.6412 1.18548 30.5435 3.28339C28.4457 5.38129 27.2652 8.22524 27.2607 11.192C27.2655 14.1586 28.446 17.0023 30.5437 19.1C32.6414 21.1977 35.4851 22.3782 38.4517 22.383C40.3833 22.3785 42.2812 21.8766 43.9626 20.9257C45.6441 19.9748 47.0524 18.607 48.0518 16.954C48.0862 16.8998 48.1094 16.8391 48.1196 16.7757C48.1299 16.7123 48.127 16.6474 48.1113 16.5851C48.0957 16.5228 48.0678 16.4643 48.0288 16.4132C47.9898 16.3621 47.9407 16.3195 47.8848 16.288L44.4329 14.188C44.3785 14.1536 44.3176 14.1307 44.2542 14.1205C44.1907 14.1103 44.1258 14.113 44.0635 14.1287C44.0011 14.1443 43.9425 14.1724 43.8914 14.2113C43.8402 14.2502 43.7976 14.2991 43.7659 14.355C43.2172 15.2728 42.4391 16.032 41.5081 16.558C40.5771 17.084 39.5251 17.3586 38.4558 17.355C37.584 17.3525 36.7224 17.1663 35.9275 16.8083C35.1326 16.4504 34.422 15.9289 33.8423 15.2777C33.2626 14.6266 32.8268 13.8605 32.5632 13.0295C32.2997 12.1985 32.2143 11.3212 32.3127 10.455C32.4868 9.03945 33.1428 7.72693 34.1704 6.73795C35.198 5.74896 36.5346 5.14375 37.9558 5.02399Z"
                    fill="white"
                />
                <path
                    d="M59.8862 17.383C58.2457 17.3782 56.6737 16.7245 55.5137 15.5645C54.3537 14.4044 53.7001 12.8325 53.6953 11.192C53.7001 9.55152 54.3537 7.97956 55.5137 6.81955C56.6737 5.65954 58.2457 5.00576 59.8862 5.00101C61.5269 5.00549 63.0991 5.65914 64.2593 6.81918C65.4195 7.97922 66.0734 9.55135 66.0781 11.192C66.0757 12.8334 65.4227 14.4069 64.262 15.5674C63.1012 16.728 61.5276 17.3809 59.8862 17.383ZM59.8862 0C56.9195 0.00476076 54.0757 1.18548 51.978 3.28339C49.8803 5.38129 48.6998 8.22527 48.6953 11.192C48.6998 14.1587 49.8803 17.0025 51.978 19.1003C54.0758 21.198 56.9196 22.3785 59.8862 22.383C62.853 22.3785 65.697 21.1981 67.7949 19.1003C69.8928 17.0026 71.0734 14.1588 71.0781 11.192C71.0776 8.22388 69.8984 5.37745 67.7996 3.27866C65.7008 1.17986 62.8544 0.000530262 59.8862 0Z"
                    fill="white"
                />
                <path
                    d="M21.6211 22.0192H24.74C24.9924 22.0186 25.2343 21.9182 25.4129 21.7398C25.5914 21.5614 25.6923 21.3196 25.6931 21.0671V1.30014C25.6923 1.04772 25.5914 0.805894 25.4129 0.627502C25.2343 0.449111 24.9924 0.348672 24.74 0.348145H21.6211C21.3688 0.348673 21.1269 0.449143 20.9485 0.627563C20.7701 0.805984 20.6695 1.04782 20.669 1.30014V21.0402C20.6678 21.2955 20.7671 21.5411 20.9451 21.7242C21.1231 21.9072 21.3658 22.0131 21.6211 22.0192Z"
                    fill="white"
                />
                <path
                    d="M13.9771 11.1848C13.9923 12.1251 13.8391 13.0605 13.5249 13.9468C13.258 14.6585 12.8352 15.3012 12.2871 15.8278C11.7757 16.328 11.1565 16.7043 10.4771 16.9278C9.75539 17.1676 8.99945 17.2882 8.23901 17.2848H5.5V5.0458H8.26294C9.02557 5.03874 9.78342 5.16742 10.501 5.42581C11.174 5.65784 11.7851 6.04097 12.2871 6.5458C12.8146 7.09172 13.2272 7.73778 13.501 8.44583C13.8379 9.31874 13.9997 10.2494 13.9771 11.1848ZM16.0491 3.20782C15.0737 2.26674 13.9144 1.53717 12.644 1.06482C11.278 0.569501 9.83514 0.319648 8.38208 0.32681H1.4519C1.19966 0.327601 0.958149 0.428171 0.779785 0.606534C0.601422 0.784897 0.500791 1.02656 0.5 1.27881V21.0188C0.50079 21.2712 0.601206 21.5129 0.779541 21.6915C0.957876 21.87 1.19957 21.9707 1.4519 21.9718H8.35791C9.81178 21.9888 11.2566 21.7386 12.6201 21.2338C13.8874 20.769 15.0449 20.0473 16.02 19.1138C16.9999 18.1482 17.7699 16.9907 18.282 15.7138C18.8463 14.2568 19.1215 12.7039 19.092 11.1418C19.1151 9.59794 18.8484 8.06343 18.3059 6.61783C17.796 5.33811 17.0278 4.17727 16.0491 3.20782Z"
                    fill="white"
                />
                <path
                    d="M21.6211 21.671H24.74C24.9924 21.6705 25.2343 21.5701 25.4129 21.3917C25.5914 21.2133 25.6923 20.9714 25.6931 20.719V0.951996C25.6923 0.69958 25.5914 0.45775 25.4129 0.279358C25.2343 0.100966 24.9924 0.000527378 24.74 0H21.6211C21.3688 0.000528428 21.1269 0.100999 20.9485 0.279419C20.7701 0.457839 20.6695 0.699672 20.669 0.951996V20.692C20.6678 20.9474 20.7671 21.1929 20.9451 21.376C21.1231 21.5591 21.3658 21.665 21.6211 21.671Z"
                    fill="white"
                />
            </svg>
        </Wrapper>
    );
};

export default DicoLogo;
