import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Checkout from './screens/CheckoutMain/CheckoutMain';

import SharedLayout from './components/SharedLayout/SharedLayout';
import NotFoundPage from './components/NotFound/NotFoundPage';
import CheckoutFailed from './screens/CheckoutFailed/CheckoutFailed';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import AttachmentSigning from './screens/AttachmentsSigning/AttachmentSigning';
import {AttachmentsSigningFailed} from './screens/AttachmentsSigning/AttachmentsSigningFailed';
import {AttachmentsSigningSuccess} from './screens/AttachmentsSigning/AttachmentsSigningSuccess';

const App: React.FC = () => (
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<SharedLayout />}>
                <Route index element={<NotFoundPage />} />
                <Route path="/invoice/:invoice_id" element={<Checkout />} />
                <Route path="/invoice/:invoice_id/failed" element={<CheckoutFailed />} />
                <Route path="/invoice/attachments/:invoice_id" element={<AttachmentSigning />} />
                <Route
                    path="/invoice/attachments/:invoice_id/failed/*"
                    element={<AttachmentsSigningFailed />}
                />
                <Route
                    path="/invoice/attachments/:invoice_id/success/*"
                    element={<AttachmentsSigningSuccess />}
                />
                <Route path="privacy_policy" element={<PrivacyPolicy />} />
                <Route path="*" element={<NotFoundPage />} />
            </Route>
        </Routes>
    </BrowserRouter>
);

export default App;
